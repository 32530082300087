@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;700&display=swap');

* {
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}

html, body, #root {
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  min-height: 100%;
}

body {
  background: #FFF;
  -webkit-font-smoothing: antialiased !important;
}

